@import '../../../styles/customMediaQueries.css';

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(60% - 56px);
}

.lastNameRoot {
  width: calc(66% - 64px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.sendInvoiceHEader{
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 25px;
}

.backButton{
  border: 0px;
  cursor: pointer;
}

.invoiceNewTitle{
  font-size: 24px;
  font-weight: var(--fontWeightBold);
}
